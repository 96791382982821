<template>
  <mf-dialog v-model="dialog" title="Histórico de churn" :max-width="max_width">
    <mf-loading-content v-if="loading" :loading="true"></mf-loading-content>
    <v-row v-else no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-if="listChurn.length" focusable>
              <v-expansion-panel v-for="(item, i) in listChurn" :key="i">
                <v-expansion-panel-header>
                  {{ item.mode === 'UNSET_CHURN' ? ' Retorno de Churn' : 'Churn' }} {{ translatePhase(item.phase) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="item.mode === 'SET_CHURN'">
                  <v-row>
                    <v-col cols="4">
                      <mf-text-input :value="retailerName" disabled label="Nome"></mf-text-input>
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="retailerCnpj" disabled label="CNPJ"></mf-text-input>
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="translatePhase(item.phase)" disabled label="Fase em que o clinte está" />
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="item.billed_client ? 'Sim' : 'Não'" disabled label="Cliente faturado" />
                    </v-col>
                    <v-col cols="4">
                      <date-picker :date="item.inactivated_at" outline disabled label="Data da ação" />
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="item.inactivated_by" disabled label="Usuário da ação" />
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="item.cause" disabled label="Causa do churn" />
                    </v-col>
                    <v-col cols="4">
                      <mf-text-input :value="item.reason" disabled label="Motivo do churn" />
                    </v-col>
                    <v-col v-if="item.responsible_area" cols="4">
                      <mf-text-input :value="translatePhase(item.responsible_area)" disabled label="Área responsável pelo Churn" />
                    </v-col>
                    <v-col cols="12">
                      <mf-text-area-input :value="item.details" disabled label="Motivo detalhado que levou ao churn"></mf-text-area-input>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else>
                  <v-row>
                    <v-col cols="12" class="mb-n2">
                      <v-row no-gutters justify="end">
                        <v-col cols="auto">
                          <a :href="`#/${type}/${clientId}/edit/acoes`">Ver mais detalhes do retorno do churn</a>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <mf-text-input :value="retailerName" disabled label="Nome"></mf-text-input>
                    </v-col>
                    <v-col cols="3">
                      <mf-text-input :value="retailerCnpj" disabled label="CNPJ"></mf-text-input>
                    </v-col>
                    <v-col cols="3">
                      <date-picker :date="item.recovered_at" outline disabled label="Data da ação" />
                    </v-col>
                    <v-col cols="3">
                      <mf-text-input :value="item.recovered_by" disabled label="Usuário da ação" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row v-else justify="center">
              <v-col cols="12">
                <v-alert type="info" outlined dense>Não há nenhum churn nesta conta.</v-alert>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="auto">
                <mf-button color="error" icon="restart_alt" label="Sair" @click="closeDialog"></mf-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </mf-dialog>
</template>
<script>
import DatePicker from '../../calendar/DatePicker.vue'

export default {
  components: {
    DatePicker
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Retailer name
     */
    retailerName: {
      type: String,
      default: ''
    },
    /**
     * Retailer's CNPJ
     */
    retailerCnpj: {
      type: String,
      default: ''
    },
    /**
     * Retailer's id
     */
    clientId: {
      type: String,
      default: ''
    },
    /**
     * List of churn
     */
    listChurn: {
      type: Array
    },
    /**
     * Loading of content
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Type
     */
    type: {
      type: String,
      default: 'retailers'
    }
  },
  data() {
    return {
      dialog: this.value,
      max_width: '1100'
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      /**
       * Update the model
       * @event input
       */
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', this.dialog)
      this.$emit('input', false)
    },
    translatePhase(phase) {
      switch (phase) {
        case 'comercial':
          return 'Comercial'
        case 'cx':
          return 'CX'
        case 'cs':
          return 'CS'
        case 'partnerships':
          return 'Parcerias'
        case 'financial':
          return 'Financeiro'
        case 'industry':
          return 'Indústria'
      }
    }
  }
}
</script>
